import React from 'react'
import { formatDate } from 'helpers'

class Announcement extends React.Component {
  render() {
    return (
      <a href={this.props.url} rel="noopener" className="c-download">
        <div className="c-download__title-block">
          <div className="c-download__title">{this.props.title}</div>
        </div>
        <div className="c-download__file-type">RNS</div>
        <div className="c-download__date">{formatDate(this.props.date)}</div>
      </a>
    )
  }
}

export default Announcement