import React from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import Layout from 'layouts/layout'
import Announcements from './_announcements'
import Header from 'components/header'


class Index extends React.Component {

  constructor(props) {
    super(props)
    this.scrollToRef = React.createRef()
    this.scrollIntoView = this.scrollIntoView.bind(this)
  }

  state = {
    loaded: false,
    error: false,
    announcementsData: null,
  }

  componentDidMount() {
    this.fetchAnnouncementsData();
  }

  fetchAnnouncementsData = () => {
    axios
      .get('https://tools.euroland.com/tools/services/?func=GetCompanyPressReleasesByDateRange&companycode=uk-huw&format=json&startdate=2010-01-01&enddate=2050-01-01')
      .then(response => {
        this.setState({
          loaded: true,
          announcementsData: response.data,
        })
      })
      .catch(error => {
        this.setState({error})
      })
  }




  scrollIntoView() {
    const element = this.scrollToRef.current;
    window.setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }, 200)
  }

  render() {

    return (
      <Layout>

        <Helmet>
          <title>
            Announcements | Helios Underwriting
          </title>
        </Helmet>

        <main>

          <Header layout="standard" />

          {/* Article fades in the content */}

          <article className="o-content">
            <section ref={this.scrollToRef}>
              <div className="lg:u-flex">
                <h1 className="h1 lg:u-2/3 padding-md">
                  Announcements
                  </h1>
                <div className="lg:u-1/3 u-color-emperor padding-md">
                  {/* If not using this section, only delete h2 and p */}
                  {/* Do not delete wrapping div */}
                </div>
              </div>
            </section>
          </article>

          {this.state.loaded && (
            <article className="o-content">
              <Announcements data={this.state.announcementsData} scrollIntoView={this.scrollIntoView} />
            </article>
          )}

        </main>
      </Layout>
    )
  }
}


export default Index