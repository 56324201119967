import React from 'react'
import Announcement from 'components/announcement'

const PER_PAGE              = 6
const MAX_PAGE_RANGE_LENGTH = 7


class Announcements extends React.Component {

  constructor(props) {
    super(props)
    this.scrollIntoView = props.scrollIntoView
    this.state = {
      pageIndex:     0,
      count:         props.data.length,
      pagesCount:    this.countPages(props.data),
      announcements: props.data,
    }
  }

  componentDidUpdate(_nextProps, prevState) {
    if (prevState.pageIndex !== this.state.pageIndex) {
      this.scrollIntoView()
    }
  }

  countPages(data) {
    return Math.ceil(data.length / PER_PAGE)
  }

  pageOfAnnouncements() {
    const beginIndex = this.state.pageIndex * PER_PAGE
    const endIndex = beginIndex + PER_PAGE > this.state.count ? this.state.count : beginIndex + PER_PAGE
    return this.state.announcements.slice(beginIndex, endIndex)
  }

  pageRangeStart(rangeLength) {

    const halfRangeLength = Math.floor(rangeLength / 2)
    const currentPage = this.state.pageIndex + 1

    let startPage = currentPage - halfRangeLength

    if (startPage < 1) {
      return 1
    }

    if (startPage + rangeLength > this.state.pagesCount) {
      return this.state.pagesCount - rangeLength + 1
    }

    return startPage
  }

  pageNavIndexes() {

    const rangeLength = this.state.pagesCount > MAX_PAGE_RANGE_LENGTH ?
      MAX_PAGE_RANGE_LENGTH :
      this.state.pagesCount

    const beginPage = this.pageRangeStart(rangeLength)

    let indexes = []
    let i = 0

    for (i; i < rangeLength; i++) { indexes.push(i + beginPage) }

    return indexes
  }

  selectPage(pageNumber) {
    this.setState({ pageIndex: pageNumber - 1 })
  }

  shiftPage(direction) {
    const pageIndex = (this.state.pageIndex + direction + this.state.pagesCount) % this.state.pagesCount
    this.setState({ pageIndex: pageIndex })
  }

  conditionalClassName(standardClassName, conditionClassName, condition) {
    let classNames = [standardClassName]
    if (condition) {
      classNames.push(conditionClassName)
    }
    return classNames.join(' ')
  }

  numberItemClassName(pageNumber) {
    return this.conditionalClassName('c-pagination__page-number', 'is-selected', pageNumber === this.state.pageIndex + 1)
  }

  linkClassName(type, condition) {
    return this.conditionalClassName(`c-pagination__${type}`, 'is-ghosted', condition)
  }

  previousLinkClassName() {
    return this.linkClassName('previous', this.state.pageIndex === 0)
  }

  nextLinkClassName() {
    return this.linkClassName('next', this.state.pageIndex + 1 === this.state.pagesCount)
  }

  render() {

    return (
      <section>
        {this.pageOfAnnouncements().map((announcement) => (
          <Announcement key={announcement.id}
            title={announcement.title}
            date={announcement.date}
            url={unescape(announcement.link)}
          />
        ))}


        <div className="c-pagination">

          <button onClick={this.shiftPage.bind(this, -1)} className={this.previousLinkClassName()}>Previous</button>

          <ul className="c-pagination__page-numbers">
            {this.pageNavIndexes().map((pageNumber) => (
              <li key={pageNumber} className={this.numberItemClassName(pageNumber)}>
                <button onClick={this.selectPage.bind(this, pageNumber)}>{ pageNumber }</button>
              </li>
            ))}

          </ul>

          <button onClick={this.shiftPage.bind(this, 1)} className={this.nextLinkClassName()}>Next</button>

        </div>


      </section>
    )
  }
}

export default Announcements